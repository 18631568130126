import { ISingleContent } from '@acfTypes';
import { ILocation, IAuditsPage, IPost } from '@standardTypes';
import { usePostWithTag } from '@utils';

import React, { useEffect, useState } from 'react';

import { Layout } from '@components/layout';
import {
  AuditsMaterials,
  SubpageHero,
  WhatInterestYou
} from '@components/molecules';

interface AboutPageProps {
  pageContext: {
    page: IAuditsPage;
    posts: IPost[];
    translationUrls: Record<string, string>;
  };
  location: ILocation;
}

const TestsPage: React.FC<AboutPageProps> = ({ pageContext }) => {
  const { page, posts, translationUrls } = pageContext;
  const { ACF_AuditsPageContent, ACF_AuditsPageSubhero } = page;
  const { contents, changeButtons } = ACF_AuditsPageContent;
  const { title, description, image } = ACF_AuditsPageSubhero;

  const [currentView, setCurrentView] = useState('1');

  const filterContent = () => {
    return contents.filter(({ contentId }) => contentId === currentView)[0];
  };

  const [currentContent, setCurrentContent] =
    useState<ISingleContent>(filterContent);

  const filteredPosts = usePostWithTag(
    posts,
    currentContent.relatedMaterials.postsTag
  );

  useEffect(() => {
    setCurrentContent(filterContent());
  }, [currentView, contents]);

  const handleViewChange = (contentId: string) => {
    setCurrentView(contentId);
  };

  return (
    <Layout {...page} translationUrls={translationUrls}>
      <SubpageHero
        title={title}
        description={description}
        image={image}
        additionalOptions={false}
      />
      <WhatInterestYou
        content={currentContent}
        changeButtons={changeButtons}
        handleViewChange={handleViewChange}
        activeView={currentView}
      />
      <AuditsMaterials posts={filteredPosts} content={currentContent} />
    </Layout>
  );
};

export default TestsPage;
